// src/App.js
import React, { useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const App = () => {

  const isRequesting = useRef(false)

  useEffect(() => {
    goUrl()
  }, [])

  const goUrl = async () => {
    if (isRequesting.current) {
      return
    }
    isRequesting.current = true
    const pathNameArr = window.location.pathname.split('/');
    let domain = ''
    if (pathNameArr.length == 2) { // 生产
      domain = 'https://api.xinqueyun.com'
    } else if (pathNameArr[1] === 'r') { // 预发布
      domain = 'https://releaseapi.eap.cn'
    } else if (pathNameArr[1] === 't') { // 测试
      domain = 'https://api.eap.cn'
    } else { // 测试
      domain = 'https://api.eap.cn'
    }

    const url = pathNameArr[pathNameArr.length - 1];
    try {
      const response = await fetch(`${domain}/user/web/shortUrl/getRealUrl?url=${url}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log("API Response:", data);
      isRequesting.current = false
      if (data && data.code == 0 && data.data && data.data.url) {
        window.location.href = data.data.url
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  return (
    <Router>
      <Routes>

        {/* 可以添加其他路由 */}
      </Routes>
    </Router>
  );
};

export default App;
